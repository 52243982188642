import { HttpInterceptorFn } from "@angular/common/http";
import { AuthService } from "../../core/services/auth.service";
import { inject } from "@angular/core";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  if (authService?.getToken()) {
    req = req.clone({
      setHeaders: {
        "Accept": "application/json",
        "Authorization": `Bearer ${authService.getToken()}`
      }
    });
  }
  return next(req);

};
