import { Routes } from "@angular/router";
import { BadGatewayComponent } from "./shared/components/bad-gateway/bad-gateway.component";
import { NotFoundPageComponent } from "./shared/components/not-found-page/not-found-page.component";
import { LoggedInGuard } from "./core/guards/logged-in.guard";
import { NotLoggedInGuard } from "./core/guards/not-logged-in.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    canActivate: [NotLoggedInGuard]
  },
  {
    path: "dashboard",
    data: { breadcrumb: "Dashboard" },
    loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: "maintenance",
    component: BadGatewayComponent
  },
  {
    path: "not-found",
    component: NotFoundPageComponent
  }
];
