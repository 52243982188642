import { Component } from '@angular/core';
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: 'app-bad-gateway',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './bad-gateway.component.html',
  styleUrl: './bad-gateway.component.scss'
})
export class BadGatewayComponent {

}
