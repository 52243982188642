import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from "@angular/core";
import { provideRouter, withInMemoryScrolling } from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { badFunctionalityInterceptor } from "./core/interceptors/bad-functionality-interceptor";
import { authInterceptor } from "./shared/interceptors/auth.interceptor";
import { LoggedInGuard } from "./core/guards/logged-in.guard";
import { NotLoggedInGuard } from "./core/guards/not-logged-in.guard";
import { DatePipe, registerLocaleData } from "@angular/common";
import localeRo from "@angular/common/locales/ro";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";

registerLocaleData(localeRo);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withInMemoryScrolling({
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    })),
    provideAnimationsAsync(),
    provideMomentDateAdapter(undefined, { useUtc: true }),
    provideHttpClient(withFetch(), withInterceptors([badFunctionalityInterceptor, authInterceptor])),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
    { provide: LOCALE_ID, useValue: "ro-RO" },
    LoggedInGuard,
    NotLoggedInGuard,
    DatePipe
  ]
};
