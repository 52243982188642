import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { DOCUMENT, isPlatformServer } from "@angular/common";


@Injectable()
export class NotLoggedInGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  canActivate(): boolean {
    const isServer = isPlatformServer(this.platformId);
    if (isServer) {
      if (this.document.defaultView?.localStorage?.getItem("token")) {
        this.router.navigate(["/dashboard"]);
        return false;
      }
    }
    return true;
  }
}
