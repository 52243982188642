import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { badFunctionalityInterceptor } from "./core/interceptors/bad-functionality-interceptor";
import { provideNativeDateAdapter } from "@angular/material/core";
import { authInterceptor } from "./shared/interceptors/auth.interceptor";
import { LoggedInGuard } from "./core/guards/logged-in.guard";
import { NotLoggedInGuard } from "./core/guards/not-logged-in.guard";
import { registerLocaleData } from "@angular/common";
import localeRo from "@angular/common/locales/ro";

registerLocaleData(localeRo);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    provideHttpClient(withFetch(), withInterceptors([badFunctionalityInterceptor, authInterceptor])),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
    { provide: LOCALE_ID, useValue: "ro-RO" },
    LoggedInGuard,
    NotLoggedInGuard
  ]
};
